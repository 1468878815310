<template>
	<div>
		<header-component />
		<b-container>
			<title-component :title="title" :background="background" />

			<div class="content-page rounded">
				<b-row class="pt-5 pb-5">
					<b-col md="6">
						<h2 class="content-page-sub-title">sublimação</h2>
						<div class="section-border"></div>
						<p class="p-4">
							A Sublimação é o método de estampar tecidos 100% poliéster através
							da transferência da tinta por meio de uma folha de papel. O conjunto
							folha, tinta e tecido é submetido a uma temperatura de 200 C° e
							pressão superior a 5 bar para que a tinta se transforme em um gás
							aderente ao tecido. Essa técnica possui alta durabilidade e permite
							o uso de estampas com os mais variados efeitos na sua camiseta e com
							qualidade fotográfica.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">silkscreen</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Também conhecido como serigrafia, é o processo de impressão no qual
							a tinta é vazada através de uma tela de nylon. Com enorme
							durabilidade, é a técnica mais utilizada em tecidos de algodão,
							piquet e poliamida. Indicada para pequenas impressões.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">bordado</h2>
						<div class="section-border"></div>
						<p class="p-4">
							O bordado, feito basicamente de linhas e agulhas, é muito utilizado
							em logotipos e brasões em camisetas. A grande vantagem dessa técnica
							é assegurar uma sofisticação às peças personalizadas. O bordado é
							indicado principalmente para tecidos de gramatura elevada, como o
							piquet e o algodão penteado.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">escudo em patch</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Os novos escudos em Patch Work são a nova sensação dentro e fora de
							campo. Aplicável a todos os tipos de tecidos, esse trabalho é
							bastante indicado para camisetas esportivas, valorizando a sua peça.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">medalha e troféu</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Ideal para quem está organizando uma competição, nós temos medalhas
							e troféus dos mais diferentes materiais, tamanhos e formas. Confira.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">display com corte especial</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Deixe sua criatividade falar mais alto na hora da formatura e outras
							homenagens! Com inúmeras formas e efeitos, o display com corte
							especial personalizado, permite você presentear de forma única
							aquela pessoa ou equipe que tanto mereceu!
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">chaveiros</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Agora a Ação Camisetas oferece essa excelente opção de
							"lembrancinhas". Com diversas opções de modelos e personalização, o
							chaveiro continua sendo uma das mais usadas opções de ser lembrado
							pelos seus clientes e parceiros. Contamos com modelos de metal,
							acrílico e MDF.
						</p>
					</b-col>
					<b-col md="6">
						<h2 class="content-page-sub-title">placa de homenagem</h2>
						<div class="section-border"></div>
						<p class="p-4">
							Uma das mais tradicionais formas de homenagem ainda vem em forma de
							placas metálicas. Sejam gravadas ou sublimadas (Novidade!!) na Ação
							Camisetas você consegue toda a gama de premiações para o seu evento!
						</p>
					</b-col>
				</b-row>
			</div>
		</b-container>
		<footer-component />
	</div>
</template>

<style scoped>
.bg-slide {
	min-height: 250px;
	background-size: cover;
	background-position: 50%;
}
</style>

<script>
import Background from "@/images/backgrounds/header-page.png"

import HeaderComponent from "@/components/Header"
import FooterComponent from "@/components/Footer"
import TitleComponent from "@/components/Title"
export default {
	data() {
		return {
			title: "Serviços",
			background: Background
		}
	},
	components: {
		HeaderComponent,
		FooterComponent,
		TitleComponent
	}
}
</script>
